<template>
    <div class="row">
        <div class="col-10 py-2">
            <multiselect v-model="selectedSubject" v-bind="multiselectSettings" @open="openSearchSubjects" @search-change="searchSubjects"
                ref="search_form_focus" :placeholder="$t('placeholder')">
                <template #option="optionProps">
                    <div class="d-flex justify-content-between align-items-center" v-if="this.type.id == 'cz'">
                        <span>{{ optionProps.option.name }} ({{ $t("organizationId") }} <i>{{ optionProps.option.organization_id }}</i>)</span>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" v-else-if="this.type.id == 'global'">
                        <span>
                            <span v-if="optionProps.option.country"
                                class="fi " :class="'fi-' + optionProps.option.country.toLowerCase()"></span>
                            <b class="ms-2" :style="{'color': nameColor(optionProps.option)}">
                                <span>{{ optionProps.option.name }}</span>
                                <span v-if="isBranch(optionProps.option)"> ({{$t("branch")}})</span>
                            </b><br>
                            <span v-if="optionProps.option.street">
                                {{ optionProps.option.street }},
                            </span>
                            <span v-if="optionProps.option.city">
                                <span v-if="optionProps.option.postcode">{{ optionProps.option.postcode }} </span>
                                {{ optionProps.option.city }},
                            </span>
                            <span v-if="optionProps.option.full_country">
                                {{ optionProps.option.full_country }} ({{ optionProps.option.country }})
                            </span>
                            <span v-else>
                                {{ optionProps.option.country }}
                            </span>
                            <br>
                            <span v-if="optionProps.option.organization_id_label && optionProps.option.organization_id">
                                {{ optionProps.option.organization_id_label }}: {{ optionProps.option.organization_id }}
                            </span>
                        </span>
                    </div>
                </template>
                <template #noResult>
                    <div v-if="isLoading" class="text-muted">
                        <span v-if="type.id == 'cz'">
                            {{ $t("searching") }}
                        </span>
                        <div v-else-if="type.id == 'global'" class="d-flex align-items-center justify-content-center">
                            <div class="d-flex align-items-center justify-content-center flex-row">
                                <div>
                                    <div class="spinner-grow" role="status"></div>
                                </div>
                                <span class="ms-3">{{ $t("international.searching") }}</span>
                            </div>
                        </div>
                    </div>
                    <span v-else class="text-muted">
                            <span v-if="type.id == 'cz'">{{ $t("changeType." + type.id )}}
                                <a @click="openChangeType" href="#"> {{ $t("changeType.here") }}</a></span>
                            <span v-else-if="error">{{ $t("international.downService") }}</span>
                            <span v-else>{{ $t("nothingFound") }}</span>
                    </span>
                </template>
                <template #noOptions class="text-muted">{{ $t("startWritting") }}</template>
                <template #beforeList v-if="subjects.length > 0">
                    <div class="d-flex py-1 ps-3 justify-content-between align-items-center before-list-types">
                        <div>
                            {{ $t("changeType." + type.id )}}
                            <a @click="openChangeType" href="#"> {{ $t("changeType.here") }}</a>
                        </div>
                    </div>
                </template>
                <template #afterList v-if="subjects.length > 8 && type.id == 'cz'">
                    <div class="d-flex py-1 ps-3 justify-content-between align-items-center before-list-types">
                        <div>
                            {{ $t("changeType.didntFound" )}}
                            <a @click="openChangeType" href="#"> {{ $t("changeType.here") }}</a>
                        </div>
                    </div>
                </template>
            </multiselect>
        </div>
        <div class="col">
            <div class="type_selection py-2">
                <multiselect v-model="type" v-bind="typeMultiselectSettings" @search-change="searchSubjects"
                    ref="change_type_form_focus" :placeholder="$t('placeholder')">
                    <template #option="optionProps">
                        <div class="d-flex justify-content-start align-items-center">
                            <span class="fi" :class="'fi-' + optionProps.option.flag"></span>
                            <span class="ms-3">{{ $t("types." + optionProps.option.id) }}</span>
                        </div>
                    </template>
                    <template #singleLabel="searchProps">
                        <div class="d-flex justify-content-center align-items-center type_selected">
                            <span class="fi fi-big" :class="'fi-' + searchProps.option.flag"></span>
                        </div>
                    </template>
                </multiselect>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import axios from "axios";

const api = axios.create({
    baseURL: "/api/",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            subjects: [],
            selectedSubject: null,
            isLoading: false,
            cancelToken: null,
            searchTimer: null,
            query: null,
            error: false,
            debounceTime: 700,
            types: [{
                "flag": "cz",
                "id": "cz"
            },{
                "flag": "global",
                "id": "global"
            }],
            type: null,
        }
    },
    mounted: function () {
        this.$i18n.locale = document.getElementById("language").innerText;
        this.type = this.types[0];
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get("search") == "international") {
            this.type = this.types[1];
        }
    },
    computed: {
        multiselectSettings: function () {
            return {
                label: "name",
                trackBy: "id",
                placeholder: "",
                options: this.subjects,
                searchable: true,
                internalSearch: false,
                clearOnSelect: false,
                showLabels: false,
                preserveSearch: true,
                openDirection: "bottom",
                optionHeight: 60
            }
        },
        typeMultiselectSettings: function () {
            return {
                label: "flag",
                trackBy: "id",
                placeholder: "",
                options: this.types,
                searchable: false,
                internalSearch: false,
                clearOnSelect: false,
                showLabels: false,
                preserveSearch: false,
                openDirection: "bottom",
                optionHeight: 60,
                allowEmpty: false
            }
        },
        subjectLink: function () {
            var object = this.selectedSubject;
            this.selectedSubject = null;
            return object ? `/subject/${object.organization_id}` : null
        },
        internationalSubjectLink: function () {
            var object = this.selectedSubject;
            var link = this.selectedSubject.link;
            this.selectedSubject = null;
            return object ? `/subject/verify/${link}` : null
        }
    },
    watch: {
        selectedSubject: function (selectedSubject) {
            this.gotToSubject();
        },
        type: function () {
            this.subjects = [];
            if (this.type && this.type.id == "global") {
                document.getElementById("powered_by_externals").classList.remove("d-none")
            }
            else if (!document.getElementById("powered_by_externals").classList.contains("d-none")){
                document.getElementById("powered_by_externals").classList.add("d-none")
            }
        },
        subjects: function () {
            var main = this.$refs.search_form_focus.$el;
            if (!main) {
                return
            }
            var elements = main.getElementsByClassName("multiselect__content-wrapper");
            if (!elements || elements.length == 0) {
                return
            }
            var element = elements[0];
            if (this.subjects && this.subjects.length > 5) {
                element.classList.add("hide_border_radius");
            }
            else {
                element.classList.remove("hide_border_radius");
            }
        }
    },
    methods: {
        isBranch: function (data) {
            return data.legal_form == "Branch";
        },
        nameColor: function (data) {
            if (this.isBranch(data)) {
                return "grey";
            }
            return "black";
        },
        openSearchSubjects: function () {
            if (this.subjects.length == 0) {
                this.searchSubjects(this.query);
            }
        },
        searchSubjects: function (query) {
            this.error = false;
            this.query = query;
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }

            if (this.cancelToken != null) {
                this.cancelToken.cancel("Operation canceled by the user.");
                this.cancelToken = null;
            }

            if (query.length < 3) {
                return;
            }

            this.isLoading = true;
            this.subjects = [];
            this.actualString = query;

            let params = {
                q: query,
                limit: 50,
                offset: 0
            }

            this.searchTimer = setTimeout(function () {
                this.cancelToken = axios.CancelToken.source();
                if (this.type.id == 'cz') {
                    this.czechSearch(params, query)
                }
                else if (this.type.id == 'global') {
                    this.internationalSearch(params, query)
                }
            }.bind(this), this.debounceTime);
        },
        czechSearch: function (params, query) {
            api.get("find", { params: params, cancelToken: this.cancelToken.token })
                .then(r => {
                    if (query == this.actualString) {
                        this.subjects = r.data;
                    }
                })
                .catch(e => {
                    if (axios.isCancel(e)) {
                        console.log("Request canceled", e.message);
                    } else {
                        console.log(e);
                    }
                })
                .finally(() => {
                    this.cancelToken = null;
                    if (query == this.actualString) {
                        this.isLoading = false;
                    }
                })
        },
        internationalSearch: function (params, query) {
            api.get("find/international", { params: params, cancelToken: this.cancelToken.token })
                .then(r => {
                    if (query == this.actualString) {
                        this.subjects = r.data.subjects;
                    }
                })
                .catch(e => {
                    if (axios.isCancel(e)) {
                        console.log("Request canceled", e.message);
                    } else {
                        if (e.response.status >= 400) {
                            this.error = true;
                        }
                        else {
                            console.log(e);
                        }
                    }
                })
                .finally(() => {
                    this.cancelToken = null;
                    if (query == this.actualString) {
                        this.isLoading = false;
                    }
                })
        },
        focusSearchForm: function () {
            this.$el.scrollIntoView(true)
            this.$refs.search_form.querySelector('input').focus()
        },
        gotToSubject: function () {
            if (this.type.id == 'cz') {
                window.location.href = this.subjectLink;
            }
            else if (this.type.id == 'global') {
                window.location.href = this.internationalSubjectLink;
            }
        },
        openChangeType: function () {
            this.$refs.change_type_form_focus.activate()
        }
    }
}
</script>

<style scoped>
</style>
