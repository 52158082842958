const cs_msg = {
    placeholder: "Zadejte IČO nebo název firmy",
    searching: "Hledáme odpovídající firmy",
    nothingFound: "Pro zadanou hodnotu nebyla nalezena žádná firma.",
    startWritting: "Začněte psát",
    organizationId: "IČO",
    branch: "Pobočka",
    international: {
        waiting: "Chvilku strpení, musíme vše prověřit v datech z více než 190 zemí světa",
        heading: "Procházíme",
        companies: "firem",
        managers: "manažerů",
        owners: "vlastníků",
        shareholders: "podílníků",
        searching: "Vteřinku prosím, prohledáváme databázi s více než 110M firem z 190+ zemí světa.",
        downService: "Vyhledávání právě není k dispozici, prosím zkuste to za chvíli.",
        errors: {
            noCredit: "Nemáte žádný volný kredit",
            noUser: "Nejste přihlášen/a, nebo nejste v žádné organizaci",
            moodys: "Data v tuto chvíli nejsou dostupná, prosíme zkuste to za chvíli",
            noCreditUsage: "Nemáte oprávnění pro využití kreditu"
        }
    },
    changeType: {
        cz: "Vyhledáváte pouze české firmy. Zahraniční můžete hledat",
        global: "Zajímají vás jen v české firmy? Hledejte",
        didntFound: "Nenašli jste to co jste hledali? Zkuste vyhledávání zahraničních firem",
        here: "zde"
    },
    types: {
        cz: "České firmy",
        global: "Zahraniční firmy"
    },
    autoscreening: {
        filter: {
            placeholder: "Název firmy nebo IČO"
        },
        subject: "Firma",
        organization_id: "IČO",
        state: "Stav",
        lastChange: "Poslední změna",
        page: "Stránka { page } z { maxPage }"
    }
}


export default cs_msg;