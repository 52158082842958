const en_msg = {
    placeholder: "Enter IČO number or company name",
    searching: "Searching for matching companies",
    nothingFound: "No company found for the entered value.",
    startWritting: "Start typing",
    branch: "Branch",
    international: {
        waiting: "A moment of patience, we have to check everything in data from more than 190 countries of the world",
        heading: "Going through",
        companies: "companies",
        managers: "managers",
        owners: "owners",
        shareholders: "shareholders",
        searching: "One sec please, we search in 110M entities from 190 countries.",
        downService: "Search is currently unavailable, please try again soon.",
        errors: {
            noCredit: "Any credit left",
            noUser: "You are not logged in or in any organization",
            moodys: "Data is not available right now, try it later please",
            noCreditUsage: "You don't have permission to use credit"
        }
    },
    changeType: {
        cz: "You search Czech entities only, for international searches change",
        global: "Do you want to search in Czech entities only. Go",
        didntFound: "Didnt found you are looking for? Go international",
        here: "here"
    },
    organizationId: "IČO",
    types: {
        cz: "Czech subjects",
        global: "International search"
    },
    autoscreening: {
        filter: {
            placeholder: "Name of company or IČO"
        },
        subject: "Company",
        organization_id: "IČO",
        state: "State",
        lastChange: "Last change",
        page: "Page {{ page }} of {{ maxPage }}"
    }
}


export default en_msg;